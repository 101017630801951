import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import tw from "twin.macro";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import CasePostList from "../Case/CasePostList";
import {
  filterOutDocsPublishedInTheFuture,
  filterOutDocsWithoutSlugs,
  mapEdgesToNodes,
} from "../../lib/helpers";
import ButtonSolid from "../Button/ButtonSolid";

var settings = {
  dots: true,
  infinite: true,
  speed: 2000,
  autoplay: false,
  autoplaySpeed: 2000,
  cssEase: "linear",
  className: "slider-container",
  slidesToShow: 3,
  slidesToScroll: 1,
  variableWidth: true,
  arrows: false,
  appendDots: (dots) => (
    <div
      style={{
        borderRadius: "10px",
        padding: "10px",
        marginTop: "50px",
      }}
    >
      <ul style={{ margin: "0px" }}> {dots} </ul>
    </div>
  ),
  customPaging: (i) => (
    <div
      style={{
        width: "8px",
        height: "8px",
        backgroundColor: "#3232D1",
        borderRadius: "50%",
        opacity: "50%",
      }}
    >
      {" "}
    </div>
  ),
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
  ],
};

const TestimonialsV2 = ({ className }) => {
  const data = useStaticQuery(graphql`
    {
      yelp: file(
        relativePath: {
          eq: "0.0 Repeating Modules/Testimonial Slider/Yelp-alt.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 61)
        }
      }
      google: file(
        relativePath: {
          eq: "0.0 Repeating Modules/Testimonial Slider/Google.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 76)
        }
      }
      facebook: file(
        relativePath: {
          eq: "0.0 Repeating Modules/Testimonial Slider/Facebook.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 99)
        }
      }
      linkedin: file(
        relativePath: {
          eq: "0.0 Repeating Modules/Testimonial Slider/Linkedin.svg"
        }
      ) {
        publicURL
      }
      linkedinAlt: file(
        relativePath: {
          eq: "0.0 Repeating Modules/Testimonial Slider/LinkedIn-alt.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 99)
        }
      }
      testimonials: allSanityCases(
        sort: { fields: [publishedAt], order: DESC }
        filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      ) {
        edges {
          node {
            id
            publishedAt
            featuredImage {
              asset {
                gatsbyImageData
              }
            }
            title
            slug {
              current
            }
          }
        }
      }
      posts: allSanityBlogs(
        sort: { fields: [publishedAt], order: DESC }
        filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      ) {
        edges {
          node {
            id
            publishedAt
            featuredImage {
              asset {
                gatsbyImageData
              }
            }
            categories {
              _id
              title
            }
            title
            slug {
              current
            }
            _rawBody(resolveReferences: { maxDepth: 5 })
          }
        }
      }
    }
  `);
  const testimonials =
    data &&
    data.testimonials &&
    mapEdgesToNodes(data.testimonials)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture);

  return (
    <section
      className={`mb-20 bg-white py-20 px-6 md:mb-32 md:py-32 md:px-0 ${className}`}
    >
      <div className="container mx-auto flex w-full max-w-none flex-col px-0">
        <header className="mb-10 text-center md:mb-6">
          <h2 className="text-[#1A1A1A]">The Results Speak for Themselves</h2>
        </header>
        <Link
          className="mx-auto mb-10 flex w-fit items-center gap-x-2 text-button-primary no-underline"
          to="/product/case-studies/"
        >
          Case Studies{" "}
          <i className="far fa-arrow-right relative left-0 text-xl transition-all duration-300 ease-linear group-hover:left-1.5"></i>
        </Link>
        <div className="flex flex-col">
          <Slider {...settings}>
            {testimonials &&
              testimonials.length > 0 &&
              testimonials.map((post, index) => (
                <Link to={post.slug.current} key={index}>
                  <GatsbyImage
                    image={post.featuredImage.asset.gatsbyImageData}
                    loading="lazy"
                    className="mb-16 w-full max-w-[500px]"
                  />
                </Link>
              ))}
          </Slider>
        </div>
        <ButtonSolid
          altStyle={2}
          // modal="modal-contact"
          href="/free-trial"
          text="Start Free Trial"
          className="left-auto right-auto mx-auto mt-10 self-center"
        />
      </div>
    </section>
  );
};

export default TestimonialsV2;
