import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ValueProps from "../components/Repeating/ValueProps";
import DesignedForRealtors from "../components/Repeating/DesignedForRealtors";
import AccelerateYourBusiness from "../components/Repeating/AccelerateYourBusiness";
import Testimonials from "../components/Repeating/Testimonials";
import Reviews from "../components/Repeating/Reviews";
import RecentPosts from "../components/Repeating/RecentPosts";
import SliderLogos1 from "../components/Slider/SliderLogos1";
import SliderLogos2 from "../components/Slider/SliderLogos2";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonGhost from "../components/Button/ButtonGhost";
import HomeIntro from "../components/Repeating/HomeIntro";
import ThreeColumnSection from "../components/Repeating/ThreeColumnSection";
import HomeStopLosingLeads from "../components/Repeating/HomeStopLosingLeads";
import MonitorAgentPerformance from "../components/Repeating/MonitorAgentPerformance";
import ReviewsGrid from "../components/Repeating/ReviewsGrid";
import TrustedBy from "../components/Repeating/TrustedBy";
import TestimonialsV2 from "../components/Repeating/TestimonialsV2";

const Page = ({ data }) => {
  const HeadingTag = "h2";
  return (
    <Layout headerStyle="overlap" marginTop="-64px">
      <SearchEngineOptimization
        title="Your Business Intelligence Platform for Data-Driven Decisions | Realsynch"
        description="Empower your business with Realsynch's business intelligence platform. Make data-driven decisions with advanced analytics, visualization, and reporting tools."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-16 bg-home-hero-mobile bg-cover bg-center bg-no-repeat pt-10 pb-14 md:mb-[42px] md:pt-10 md:pb-26 lg:bg-home-hero-desktop">
        <div className="container">
          <div className="flex flex-col-reverse items-center gap-y-12 gap-x-3 pt-[10px] md:grid-cols-2 lg:grid lg:pt-[50px]">
            <div className="mx-auto w-full leading-[41.7px] md:px-2 md:text-left lg:px-8">
              <div className="text-start font-heading text-[20px] font-semibold leading-[30px] text-[#F7F7F7]">
                For real estate agents, teams, and brokerages
              </div>
              <div className="mb-5 mt-6 inline-block ">
                <span className="font-heading text-[32px] font-extrabold leading-[41.7px] text-white lg:text-[56px]  lg:leading-[72.97px]">
                  Business intelligence to
                </span>
                <span className="ml-2 bg-brand-linear-gradient-2 bg-clip-text bg-top font-heading text-[32px] font-extrabold leading-[41.7px] text-transparent lg:text-[56px] lg:leading-[72.97px]">
                  maximize profitability.
                </span>
              </div>
              <p className="text-[20px] leading-[30px] text-[#F7F7F7] md:mb-8">
                Done-for-you tracking and integration. No tech skills required.
              </p>
              <div className="mx-auto flex w-full justify-center gap-x-6 lg:mx-0 lg:justify-start">
                <ButtonSolid
                  altStyle={2}
                  // modal="modal-contact"
                  href="/free-trial"
                  text="Start Free Trial"
                  className="min-w-[100px] max-w-[156px]"
                />
                <ButtonSolid
                  altStyle={3}
                  // modal="modal-contact"
                  href="/free-trial"
                  text="Request Demo"
                  className="min-w-[100px] max-w-[156px]"
                />
              </div>
            </div>
            <div className="w-full lg:col-start-2">
              <div className="">
                <div className="mx-auto grid w-full grid-cols-1 grid-rows-1 lg:h-[745px] lg:w-[736px]">
                  <img
                    src="/images/home-hero/hero-screen.png"
                    width={736}
                    height={745}
                    className="z-10 col-start-1 row-start-1"
                  />
                  {/* <StaticImage
                    src="../images/1.0 Homepage/floating/dots and top orbits.svg"
                    loading="eager"
                    height={946}
                    width={1248}
                    className="z-10 col-start-1 row-start-1 w-full -translate-x-[30px] scale-125"
                  /> */}
                  <StaticImage
                    src="../images/1.0 Homepage/floating/Icon 1.png"
                    loading="eager"
                    width={131}
                    height={131}
                    className="floating z-0 col-start-1 row-start-1 mt-[12%] ml-[35%] h-[30px] w-[30px] lg:h-[64px] lg:w-[64px]"
                  />
                  <StaticImage
                    src="../images/1.0 Homepage/floating/Icon 2.png"
                    loading="eager"
                    height={131}
                    width={131}
                    className="floating z-10 col-start-1 row-start-1 mt-[3%] ml-[60%] h-[31px] w-[31px] lg:h-[68px] lg:w-[68px]"
                  />
                  <StaticImage
                    src="../images/1.0 Homepage/floating/Icon 3.png"
                    loading="eager"
                    height={131}
                    width={131}
                    className="floating2 z-10 col-start-1 row-start-1 mt-[10%] ml-[75%] h-[87px] w-[87px] lg:h-[180px] lg:w-[180px]"
                  />
                  <StaticImage
                    src="../images/1.0 Homepage/floating/Icon 4.png"
                    loading="eager"
                    height={131}
                    width={131}
                    className="floating3 z-10 col-start-1 row-start-1 mt-[30%] ml-[78%] h-[100px] w-[100px] lg:h-[180px] lg:w-[180px]"
                  />
                  <StaticImage
                    src="../images/1.0 Homepage/floating/Icon 5.png"
                    loading="eager"
                    height={131}
                    width={131}
                    className="floating3 z-10 col-start-1 row-start-1 mt-[74%] ml-[40%] h-[90px] w-[90px] lg:h-[180px] lg:w-[180px]"
                  />
                  <StaticImage
                    src="../images/1.0 Homepage/floating/Icon 6.png"
                    loading="eager"
                    height={131}
                    width={131}
                    className="floating2 z-10 col-start-1 row-start-1 mt-[85%] ml-[23%] h-[44px] w-[44px] lg:h-[100] lg:w-[100]"
                  />
                  <StaticImage
                    src="../images/1.0 Homepage/floating/Icon 7.png"
                    loading="eager"
                    height={131}
                    width={131}
                    className="floating z-10 col-start-1 row-start-1 mt-[78%] ml-[6%] h-[38px] w-[38px] lg:h-[77px] lg:w-[77px]"
                  />
                  <StaticImage
                    src="../images/1.0 Homepage/floating/Icon 8.png"
                    loading="eager"
                    height={131}
                    width={131}
                    className="floating z-0 col-start-1 row-start-1 mt-[55%] ml-[6%] h-[28px] w-[28px] lg:h-[64px] lg:w-[64px]"
                  />
                </div>
                {/* <svg
                  className="rotating absolute inset-0 m-auto w-[88%]"
                  viewBox="0 0 480 480"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="240"
                    cy="240"
                    r="238.5"
                    stroke="url(#paint0_linear_1147_2430)"
                    strokeWidth="3"
                    strokeDasharray="6 6"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1147_2430"
                      x1="0"
                      y1="480"
                      x2="567.523"
                      y2="150.957"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#673DEC" />
                      <stop offset="1" stopColor="#51E7FF" />
                    </linearGradient>
                  </defs>
                </svg> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <TrustedBy />
      <ReviewsGrid
        otherClasses="pt-16 pb-20 md:pt-20 md:pb-32"
        showMainButton={true}
      />
      <HomeIntro />
      <ThreeColumnSection headingLevel="h1" className="mb-20 md:mb-32" />
      <HomeStopLosingLeads />
      <MonitorAgentPerformance />
      <section className={"mb-20 md:mb-32"}>
        <div className="container mx-auto max-w-[1267px]">
          <div className="grid items-center gap-y-8 md:grid-cols-1 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 flex flex-col items-center md:order-1">
              <HeadingTag className="text-center">
                Boost Performance: Real-Time Leaderboards Drive Success!
              </HeadingTag>
              <p className="mx-auto max-w-[640px] text-center">
                Empower your team with real-time leaderboards to celebrate wins
                and streamline agent KPI tracking. Drive a competitive
                atmosphere, and navigate all your metrics in one convenient
                dashboard.
              </p>
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/1.0 Homepage/Boost Performance Levels.jpg"
                loading="lazy"
                objectFit="contain"
                width={1262}
                height={610}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="mb-20 overflow-x-hidden rounded-tl-[120px] rounded-br-[120px] bg-primary-900 py-20 md:mb-28 md:rounded-tl-[240px] md:rounded-br-[240px] md:py-32">
        <div className="container">
          <div className="mb-6 text-center text-[20px] font-semibold text-white">
            Easy Integration
          </div>
          <header className="mx-auto mb-12 max-w-[800px] text-center md:mb-20">
            <h2 className="text-white">
              <span className="bg-gradient-to-r from-[#3D3DF1] via-[#9F4ED1] to-[#FEA6DD] bg-clip-text pr-[2px] italic text-transparent">
                Synch
              </span>{" "}
              Your Apps.
              <br /> View Everything on One Dashboard.
            </h2>
            <p className="text-white/80">
              Realsynch integrates the systems you use every day, and we
              continually partner with new providers to help you connect every
              tool you’re using.
            </p>
          </header>

          <div className="mb-10 md:mb-20">
            <SliderLogos1>
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/BoomTown.png"
                loading="lazy"
                width={294}
                className="mx-[10px] max-h-[71px] max-w-[148px] rounded-[10px] md:max-w-[294px] lg:max-h-[164px] lg:rounded-[23px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/Brivity.png"
                loading="lazy"
                width={294}
                className="mx-[10px] max-w-[128px] rounded-[10px] md:max-w-[294px] lg:rounded-[23px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/Chime.png"
                loading="lazy"
                width={294}
                className="mx-[10px] max-w-[128px] rounded-[10px] md:max-w-[294px] lg:rounded-[23px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/dotloop.png"
                loading="lazy"
                width={294}
                className="mx-[10px] max-w-[128px] rounded-[10px] md:max-w-[294px] lg:rounded-[23px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/Evabot.png"
                loading="lazy"
                width={294}
                className="mx-[10px] max-w-[128px] rounded-[10px] md:max-w-[294px] lg:rounded-[23px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/Firepoint.png"
                loading="lazy"
                width={294}
                className="mx-[10px] max-w-[128px] rounded-[10px] md:max-w-[294px] lg:rounded-[23px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/Follow Up Boss.png"
                loading="lazy"
                width={294}
                className="mx-[10px] max-w-[128px] rounded-[10px] md:max-w-[294px] lg:rounded-[23px]"
              />

              <StaticImage
                src="../images/1.0 Homepage/Logo slider/HomeLight.png"
                loading="lazy"
                width={294}
                className="mx-[10px] max-w-[128px] rounded-[10px] md:max-w-[294px] lg:rounded-[23px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/HubSpot.png"
                loading="lazy"
                width={294}
                className="mx-[10px] max-w-[128px] rounded-[10px] md:max-w-[294px] lg:rounded-[23px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/LionDesk.png"
                loading="lazy"
                width={294}
                className="mx-[10px] max-w-[128px] rounded-[10px] md:max-w-[294px] lg:rounded-[23px]"
              />
            </SliderLogos1>

            <SliderLogos2>
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/OjoLabs.png"
                loading="lazy"
                width={294}
                className="mx-[10px] max-w-[128px] rounded-[23px] md:max-w-[294px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/Opcity.png"
                loading="lazy"
                width={294}
                className="mx-[10px] max-w-[128px] rounded-[23px] md:max-w-[294px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/RealGeeks.png"
                loading="lazy"
                width={294}
                className="mx-[10px] max-w-[128px] rounded-[23px] md:max-w-[294px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/Salesforce.png"
                loading="lazy"
                width={294}
                className="mx-[10px] max-w-[128px] rounded-[23px] md:max-w-[294px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/Showcase IDX.png"
                loading="lazy"
                width={294}
                className="mx-[10px] max-w-[128px] rounded-[23px] md:max-w-[294px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/Sierra.png"
                loading="lazy"
                width={294}
                className="mx-[10px] max-w-[128px] rounded-[23px] md:max-w-[294px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/Sisu.png"
                loading="lazy"
                width={294}
                className="mx-[10px] max-w-[128px] rounded-[23px] md:max-w-[294px]"
              />

              <StaticImage
                src="../images/1.0 Homepage/Logo slider/Skyslope.png"
                loading="lazy"
                width={294}
                className="mx-[10px] max-w-[128px] rounded-[23px] md:max-w-[294px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/Structurely.png"
                loading="lazy"
                width={294}
                className="mx-[10px] max-w-[128px] rounded-[23px] md:max-w-[294px]"
              />
              <StaticImage
                src="../images/1.0 Homepage/Logo slider/VoicePad.png"
                loading="lazy"
                width={294}
                className="mx-[10px] max-w-[128px] rounded-[23px] md:max-w-[294px]"
              />
            </SliderLogos2>
          </div>

          <div className="grid justify-center gap-y-6 md:flex md:gap-y-0 md:space-x-6">
            <ButtonSolid
              altStyle={2}
              href="/real-estate-software-integrations/"
              text="Explore Integrations"
            />
            <ButtonSolid
              altStyle={3}
              text="Become a Partner"
              // modal="modal-contact"
              href="/free-trial"
            />
          </div>
        </div>
      </section>
      <TestimonialsV2 />
      {/* <AccelerateYourBusiness />
      <Testimonials />
      <Reviews />
      <RecentPosts /> */}
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
    business: file(relativePath: { eq: "home/business.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 528)
      }
    }
    posts: allSanityBlogs(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          featuredImage {
            asset {
              gatsbyImageData
            }
          }
          categories {
            _id
            title
          }
          title
          slug {
            current
          }
          _rawBody(resolveReferences: { maxDepth: 5 })
        }
      }
    }
  }
`;

export default Page;
